<template>
    <base-page>

        <el-card style="margin: 5px 0px 5px 0px">
            <el-collapse accordion style="border: none">
                <el-collapse-item name="1" style="border: none">
                    <template slot="title">
                        <div slot="header" class="clearfix">
                            <titlu-pagina Titlu="Top vânzări" @on_add_clicked="show_dialog()" :AdaugaVisible="false" />
                        </div>
                    </template>
                    <div class="filtre">
                        <el-form @submit="refresh_info()" label-position="top">

                            <el-row>

                                <el-col class="input-element">
                                    <el-form-item label='Interval'  size="small" >
                                        <el-date-picker size="small"
                                            v-model="Filters.Interval"
                                            type="daterange"
                                            range-separator="-"
                                            start-placeholder="Data inceput"
                                            end-placeholder="Data sfarsit"
                                            :picker-options="datePickerOptions"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col>
                                    <!-- <el-form-item label="Tip">
                                        <el-select v-model="Filters.Tip" class="full-width">
                                            <el-option label="Toate" value="-1"/>
                                            <el-option v-for="item in Info.gestiuni" :key="item.Id + item.Nume" :label="item.Nume" :value="item.Id" />
                                        </el-select>
                                    </el-form-item> -->
                                </el-col>

                            </el-row>



                            <el-col>
                                <div class="footer-form">
                                    <el-button type="primary" native-type="submit" @click="refresh_info()">Aplica
                                    </el-button>
                                    <el-button type="danger" @click="reset()">Reseteaza</el-button>
                                </div>
                            </el-col>

                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </el-card>
        <el-card class="chart-container" style=" margin: 5px 0px 5px 0px; background: lightgray !important;">
            <span v-if="this.Results.length < 1">Nu exista date </span>
            <Bar  v-else 
            :height="200"
            :options="options" :chart-data='chart' :key="chartKey" :chart-id="'1'" />
            <!-- style="height:80vh;" -->
            <!-- :height="this.chart.labels.length * 20" -->
        </el-card>
    </base-page>

</template>

<script>
import moment from "moment";
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Clienti_dialog from "@/pages/clienti/Clienti_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {


    name: 'app',
    extends: BasePage,
    components: {
        "base-page": BasePage,
        "Clienti-dialog": Clienti_dialog,
        "titlu-pagina": TitluPagina,
        Bar
    },
    
    data() {
        return {
            inaltime: 800,
            datePickerOptions: settings.datePickerOptions,
            Results: [],
            chartKey: 0,
         
            options: {    
                height: 300,
                responsive: false,
                maintainAspectRatio: false,  
                
                // plugins: {
                //     customCanvasBackgroundColor: {
                //         color: 'lightGreen',
                //     }
                //   },
                // color:"green",
                // defaultFontColor:"green"  ,
                },
            chart: {
                labels: [],
                datasets: [
                   {label: 'Vanzari',
                    barPercentage: 0.4,
                    indexAxis: 'x',
                    data: [],
                    backgroundColor: ['yellow'],
                    borderColor: ['black'],
                    borderWidth: 1},
                    {label: 'Incasari',
                    barPercentage: 0.4,
                    indexAxis: 'x',
                    data: [],
                    backgroundColor: ['orange'],
                    borderColor: ['black'],
                    borderWidth: 1},
                ]
            },
            Filters: {
                Interval: [moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            },
        }

        
    },
    
    methods: {
        
        async refresh_info() {
  
            var response = await this.post("Rapoarte/Top_vanzari/get_top_vanzari_nou", { Filters: this.Filters });

            this.Results = response.date_vanzari;

            if(this.Results == null) return;
            
            if (this.Results.length == 0) {
                this.chart.labels = [];
                this.chart.datasets[0].data = [];
            }

            for (var i = 0; i < this.Results.length; i++) {
                this.chart.labels[i] = this.Results[i].NumeClient;
                this.chart.datasets[0].data[i] = this.Results[i].vanzari;
                this.chart.datasets[1].data[i] = this.Results[i].incasari;
            }

            this.Chart_created = 1;
            this.chartKey += 1;

            // this.chart.height = this.chart.labels.length * 12 + 24;
            // this.chart.height = "3px";

        },
        reset() {
            this.Filters = {
                Interval: [moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            };
            this.refresh_info();
        },
    },
    mounted: function () {
        this.refresh_info();
    }
}

</script>

<style lang="less" scoped>


.chart-container {
  flex-grow: 1;
  min-height: 0;

  > div {
    position: relative;
    height: 10%;
  }
}
.filtre {
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(2);


    

}
</style>